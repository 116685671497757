import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FFeatureSection%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VTy3KDIBTd%2BxV305lkQQcf0RQ3%2FY2uOlRvIhNFB0lj2%2FHfOyJWNOlrpeJ5cbg8nvDtoHiFLTwnRU1P1QN8eAD0zjwAtOKyPdSqYuNryTU%2Bbeg29QB6DyDY%2FYwkUdN9gZNfwC7Wp3%2FM0Hv3Nrpv8EfeMAgUVsNvXoqjJEJj1TLIUGpUqcOgTOqCZIUo8w2%2BotzCUutQYkdyoTDTopYMVH0hCl9RtejKBAZc8Y5cRK4LBtGeNt3gn4u2KfkbM0qpAamjkAwo8LOuU%2BvBwE9v2GV1ea5kOm3KN5uabcO17S6yto6m%2FTXU6XKjNdffGe6M2I0N1FKTVrwjg8CKl0IiKVAcC80gDMZFjZ0mzkGdmwZVxoeerMjFMhJKXZt4bRPGyxzJdzk2TvokTppua%2BfFwfpxapYWmYNoFHBnZ2%2B4X8f1UtbZ6ao%2BgEli%2Bh4izLL2fB4rzAVf5POj8GEKuCx4GXc%2FJlsH3s%2BBb4g7m1%2BO72r%2BW61QZ8VocHvW5ukeb%2BFfL8p%2F9ULLur4O%2FS8NxbcbolNDMzu%2BZk8n%2F80Q917%2FCcjlOx8RBQAA%22%7D"
export var actionWrapperStyle = '_7ho0km4';
export var artStyle = '_7ho0km3';
export var blockStyle = '_7ho0km0';
export var bounce = '_7ho0km9';
export var contentStyle = '_7ho0km2';
export var labelStyle = '_7ho0km5';
export var logoSvgStyle = '_7ho0km8';
export var rootStyle = '_7ho0km1';
export var textStyle = '_7ho0km7';
export var titleStyle = '_7ho0km6';