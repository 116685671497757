import React from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { RemoteImage } from "@/uikit/remote-image";
import { Typography } from "@/uikit/Typography";

import {
  rootStyle,
  contentStyle,
  artStyle,
  labelStyle,
  titleStyle,
  logoSvgStyle,
  blockStyle,
  textStyle,
  actionWrapperStyle,
} from "./styles.css";
import { renderActions } from "../schema-mappers";
import { FlexContainer } from "@/uikit/container";

const FeatureBlock = Block("feature", (props) => {
  const { label, title, text, image, settings, actions } = props;
  return (
    <BlockContainer className={blockStyle} defaultTheme="light" settings={settings}>
      <FlexContainer alignItems="start" justifyContent="center" className={rootStyle}>
        <div className={contentStyle}>
          <Typography.subtitle className={labelStyle}>{label}</Typography.subtitle>
          <Typography.h4 className={titleStyle}>{title}</Typography.h4>
          <Typography className={textStyle} settings={{ tag: "p" }}>
            {text}
          </Typography>
          {actions && <div className={actionWrapperStyle}>{renderActions(actions)}</div>}
        </div>
        <div className={artStyle}>
          <RemoteImage
            className={logoSvgStyle}
            image={image}
            sizes={{
              mobile: "90vw",
              tablet: "40vw",
              desktop: "30vw",
            }}
          />
        </div>
      </FlexContainer>
    </BlockContainer>
  );
});

export default FeatureBlock;
